<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Edit billing details and view past purchases
      </span></template
    >
    <form
      role="form"
      class="billing-history-form"
      method="post"
      @submit.prevent="$emit('handle-submit')"
    >
      <b-row class="justify-content-between" style="margin: 0">
        <b-col lg="8" style="padding: 0">
          <span class="purchase-heading">Payment Information</span>
        </b-col>
        <add-card-modal></add-card-modal>
      </b-row>
      <b-row>
        <payment-method-display
          v-for="p in paymentMethods"
          :key="p.id"
          :paymentMethod="p"
        ></payment-method-display>
        <div class="row" v-if="paymentMethods.length == 0">
          <div
            v-if="loadingMethods"
            class="text-center align-items-center"
            style="margin: auto"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </div>
        <!--b-col lg="6">
                <b-form-group label="Card Number">
                  <base-input
                    class="mb-4"
                    v-model="cardNumber"
                    placeholder="Card Number"
                    rows="3"
                    disabled
                  ></base-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Expiration Date">
                  <base-input
                    class="mb-4"
                    v-model="expiration"
                    placeholder="Expiration Date"
                    rows="3"
                    disabled
                  ></base-input>
                </b-form-group>
              </b-col-->
      </b-row>
    </form>
    <div class="purchase-heading">Purchase History</div>
    <table class="table table-striped table-hover activities-table jobs-table">
      <thead>
        <tr>
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, index) in pastPurchases" :key="index">
          <td style="font-weight: 600">
            {{ new Date(p.created * 1000).toLocaleDateString() }}
          </td>
          <td>{{ p.metadata.product_name }}</td>
          <td>
            {{ capitalizeFirstLetter(p.payment_method_details.card.brand) }} *{{
              p.payment_method_details.card.last4
            }}
          </td>
          <td>${{ p.amount / 100 }}</td>
          <td class="action-data">
            <div
              class="actions-wrapper"
              ref="wrapper"
              style="width: fit-content"
            >
              <!-- <PhDotsThreeCircle 
                    size="40"
                    weight="thin"
                    class="action-btn"
                    @click="togglePopover('popover-' + item._id, $event)"
                  />
                  <div class="popover-action" ref="popover" :id="'popover-' + item._id">
                    <ul>
                      <li @click="handleApplyForJob(item._id, item.company[0]._id)">
                        <PhCheck size="20" color="#525E77" weight="bold" />Apply to
                        Job
                      </li>
                      <li @click="$router.push('/jobs/' + item._id)">
                        <PhEye size="20" color="#525E77" weight="bold" />View Job
                      </li>
                    </ul>
                  </div> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!--b-modal id="add-new-card" hide-footer>
      <h1>Add New Card</h1>
      <form class="mt-4">
        <b-row class="mt-4">
          <div id="payment-element" class="payment-element">
          </div>
        </b-row>
        <div class="text-right">
        <b-button
          class="btn btn-orange btn-rounded"
          @click="addNewCard"
          >Submit</b-button
        >
        </div>
       </form>
    </b-modal-->
  </inner-layout-action-bar-wrapper>
</template>

<script>
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import stripeMixin from "@/mixins/stripeMixin"
import addCardModal from "@/components/Payment/AddCardModal"
import PaymentMethodDisplay from "@/components/Payment/PaymentMethodDisplay.vue"
export default {
  name: "Billing",
  mixins: [stripeMixin],
  components: {
    InnerLayoutActionBarWrapper,
    addCardModal,
    PaymentMethodDisplay,
  },
  data() {
    return {
      paymentMethods: [],
      selectedPaymentMethod: null,
      fields: {
        name: this.$store.state.userProfile.name,
        address: this.$store.state.userProfile.address,
        city: this.$store.state.userProfile.city,
        state: this.$store.state.userProfile.state,
        zip: this.$store.state.userProfile.zip,
      },
      cardNumber: null,
      expiration: null,
      customer: "",
      pastPurchases: [],
      loadingData: false,
      loadingMethods: false,
      columns: ["Date", "Purchased", "Card Info", "Amount", "Actions"],
    }
  },
  async created() {
    await this.getPaymentMethods()
    await this.getCharges(this.customer)
  },
  methods: {
    async getPaymentMethods() {
      try {
        this.loadingMethods = true
        const response = await this.$store.dispatch("api/getPaymentMethods")
        if (response.data.errors) {
          this.logDevError(response.data?.errors?.[0])
        } else {
          const profile = this.$store.state.userProfile
          const defaultPaymentMethodId = profile?.defaultPaymentMethod
          const paymentMethods = response.data
          this.paymentMethods = paymentMethods ?? []
          const currentPaymentMethod = paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === this.currentPaymentMethod
          )
          const defaultPaymentMethod = paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === defaultPaymentMethodId
          )
          const selectedPaymentMethod =
            currentPaymentMethod ??
            defaultPaymentMethod ??
            paymentMethods[0] ??
            null
          this.selectedPaymentMethod = selectedPaymentMethod
          this.cardNumber =
            "Ending in " + this.selectedPaymentMethod?.card?.last4
          this.expiration =
            this.selectedPaymentMethod?.card?.exp_month +
            "/" +
            this.selectedPaymentMethod?.card?.exp_year
          this.customer = this.selectedPaymentMethod?.customer
        }
        this.loadingMethods = false
      } catch (e) {
        this.loadingMethods = false
        console.error(e)
      }
    },
    async getCharges() {
      try {
        this.loadingData = true
        let pastPurchases = await this.$store.dispatch("api/getPaymentHistory")
        console.log(pastPurchases.data)
        this.pastPurchases = pastPurchases.data
        this.loadingData = false
      } catch (e) {
        this.loadingData = false
        console.error(e)
      }
    },
    capitalizeFirstLetter(str) {
      if (str && typeof str === "string") {
        return str.charAt(0).toUpperCase() + str.slice(1)
      }
      return str
    },
  },
}
</script>
<style lang="scss" scoped>
.payment-method {
  width: 100%;
  margin-top: 12px;
}
.purchase-heading {
  color: var(--Gray-900, #343946);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 48px 0 16px;
}

.billing-history-form {
  max-width: unset;
}
</style>
