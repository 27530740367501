<template>
  <b-modal
    hide-header
    hide-header-close
    hide-footer
    dialog-class="modal-no-padding dashboard-profile-modal"
    id="dashboard-profile-modal"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {}
</script>

<style lang="scss" global>
.dashboard-profile-modal .form-container {
  --border-radius: 0.4735rem;
  border-radius: var(--border-radius);
  margin: 0;
  background: var(--Gray-50, #f6f7f9);

  & .form-content-body {
    padding: 2rem;
  }

  & .form-content-footer {
    border-radius: var(--border-radius);
  }

  .address-wrapper select,
  .education-select {
    font-weight: 500;
    border: 2px solid rgba(0, 48, 73, 0.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 14px;
    height: 50px;
    line-height: 50px;
    padding: 0 1rem;
    font-size: 18px;
    max-width: 200px;
    appearance: none;
    background-image: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
  }

  .education-select {
    max-width: 365px;
    padding: 0;
    border: unset;

    .v-select__selection--comma {
      font-weight: normal;
      font-size: 16px;
      color: var(--Gray-900, #343946);
    }
  }

  .multiselect__selected-category-list {
    background: #ffffff;
  }
}
</style>
